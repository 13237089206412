import React, {useContext} from "react"
import {content} from "../../constants/Instances"
import Typography from "../../components/reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import DataContext from "../../context/DataContext"
import OrganizationContext from "../../context/OrganizationContext"

type Props = {
    instanceShowHPText:boolean
}
function CustomInstancesBlockText({instanceShowHPText}: Props) {
	const {organizationSlug} = useContext(OrganizationContext)
	const {t} = useTranslation()
	return (
		<div className="pt-5 container">{instanceShowHPText && process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug && content[organizationSlug]?.home && (
			<>
				<div
					className={"d-md-none d-flex align-items-center justify-content-start gap-2 text-start"}>
					<img alt={content[organizationSlug]?.companyName} src={content[organizationSlug]?.companyIcon} height={26} width={"auto"}/>
					<Typography semanticTag="span" variant={"heading2"}
					>
						{t(content[organizationSlug]?.home?.subTitle).replace("instance", content[organizationSlug]?.companyName)}
					</Typography>
				</div>
				<div
					className={"d-none  mb-2 col-12  d-md-flex align-items-start gap-2 justify-content-start text-left}"}>
					<img alt={content[organizationSlug]?.companyName} src={content[organizationSlug]?.companyIcon} height={32} width={"auto"}/>
					<Typography semanticTag="h2" variant={ "heading2Black"}
					>
						{t(content[organizationSlug]?.home?.subTitle).replace("instance", content[organizationSlug]?.companyName)}
					</Typography>
				</div>
			</>
		)}
		{instanceShowHPText && Boolean(process.env.NEXT_PUBLIC_CUSTOM_INSTANCE) && content[organizationSlug]?.home && (
			<Typography semanticTag="span" variant={"bodyLg"}
				className={"mb-2 container col-12 text-start text-md-start"}>
				{t(content[organizationSlug]?.home?.text).replace("instance", content[organizationSlug]?.companyName)}
			</Typography>
		)

		}</div>
	)
}

export default CustomInstancesBlockText
