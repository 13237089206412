import React, {useContext, useState} from "react"
import PropTypes from "prop-types"
import {TextAlign} from "../textAlign"
import {BackgroundColor} from "../backgroundColor"
import Image from "next/image"
import RichText from "./RichText"
import Typography from "../../components/reusable/Typography/Typography"
import LayoutContext from "../../context/LayoutContext"
import {useTranslation} from "next-i18next"

interface Props {
  fields: any;
  textClassname?: string
  containerMargin?: string
  homeText?: boolean,

}

const BlockText: React.FC<Props> = ({
	fields,
	textClassname = "mb-4",
	containerMargin
}) => {
	const {
		title,
		titleStyle,
		titleStyleMobile,
		faded,
		text,
		textAlign,
		textAlignMobile,
		backgroundColor,
		collapsible,
		classNames,
		width
	} = fields

	const [isToggleOpen, setIsToggleOpen] = useState(true)
	const {t} = useTranslation("common")

	const style = isToggleOpen && collapsible ? {height: "40vh", overflow: "hidden"} : {
		height: "100%",
		overflow: "hidden"
	}
	const styleOverlay = isToggleOpen ? {
		background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 95%)"
	} : {backgroundColor: "transparent"}
	const {isMobile} = useContext(LayoutContext)

	return (
		<div
			className={`container ${
				backgroundColor === BackgroundColor.lightGrey ? "bg-light" : ""
			} ${
				backgroundColor ?
					"pt-5" :
					containerMargin ?
						containerMargin :
						classNames ?
							classNames :
							"mt-lg-5"
			}`}
		>
			<section>
				<div className={"row justify-content-center"}>
					<div className={`col-12 ${width || "col-lg-10"}`}>
						<div style={style} className={"row"}>
							{title && (
								<>
									<div
										className={`d-md-none col-12 ${textAlignMobile === TextAlign.center ? "text-center" : textAlignMobile === TextAlign.right ? "text-end" : "text-start"}`}>
										<Typography semanticTag="h2" variant={titleStyleMobile || "heading2"}
											className={`mb-2 ${faded ? "text-secondary" : ""}`}>
											{title}
										</Typography>
									</div>
									<div
										className={`d-none d-md-block  ${process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? "justify-content-start" : "justify-content-center"} col-12 ${textAlign === TextAlign.center ? "text-center" : textAlign === TextAlign.right ? "text-end" : "text-start"}`}>
										<Typography semanticTag="h2" variant={titleStyle || "heading2Black"}
											className={`mb-2 ${faded ? "text-secondary" : ""}`}>
											{title}
										</Typography>
									</div>
								</>
							)}
							{text?.content && (
								<RichText content={text.content} textAlign={"text-center text-md-start"}
									style={{marginTop: 8}}
									paragraphVariant={"bodyLg"}
									isMobile={isMobile}
									className={textClassname}/>
							)}
							{collapsible && (
								<div
									className={`${
										isToggleOpen ? "" : "d-none"
									} position-absolute start-0 end-0 bottom-0 vh-20`}
									style={styleOverlay}
								/>
							)}
						</div>
					</div>
					{collapsible && (
						<div
							className={"pt-3 pt-md-5 bg-transparent"}>
							<div
								className={"col-12 text-center d-flex justify-content-start justify-content-md-center"}
								style={{
									cursor: "pointer"
								}}
								onClick={() => setIsToggleOpen(!isToggleOpen)}
							>
								{isToggleOpen ? (
									<div
										className="d-flex mt-3 align-items-center bottom-0 position-absolute">
										<Typography variant="bodyLgBold" semanticTag="h3" className="mb-0">{t("Show more")}</Typography>
										<div className="d-flex justify-content-center align-items-center"
											style={{width: 20, height: 20, marginLeft: 10}}>
											<Image src={"/assets/icons/chevron-down-icon.svg"} width={8.33} height={5}/>
										</div>
									</div>
								) : (
									<div className="d-flex mt-3 align-items-center">
										<Typography variant="bodyLgBold" semanticTag="h3" className="mb-0">{t("Show less")}</Typography>
										<div className="d-flex justify-content-center align-items-center"
											style={{width: 20, height: 20, marginLeft: 10}}>
											<Image src={"/assets/icons/chevron-up-icon.svg"} width={8.33} height={5}/>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</section>
		</div>
	)
}

BlockText.propTypes = {
	fields: PropTypes.any.isRequired,
	textClassname: PropTypes.string,
	containerMargin: PropTypes.string
}

export default BlockText
